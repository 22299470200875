.animate-in {
  position: relative;

  .animate-content {
    position: relative;
    z-index: 60;
    transition: opacity @transition;
    opacity: 0;
  }

  .loading-wp {
    width: 100%;
    max-height: 200px;
    transition: max-height @transition, opacity @transition;
    opacity: 1;
    overflow: hidden;
  }
}

.start-animate-in {
  .loading-wp {
    opacity: 0;
    max-height: 0px;
  }

  .animate-content {
    opacity: 1;
  }
}