// Flexbox Mixins
// ------------------------------------------


// Display
.flex-box(@display: flex) {
  display:~"-webkit-@{display}";
  display:~"-moz-@{display}";
  display:~"-ms-@{display}box"; // IE10 uses -ms-flexbox
  display:~"-ms-@{display}"; // IE11
  display: @display;
}


// Flex Shorthand
.flex(@columns: initial) {
  -webkit-flex: @columns;
  -moz-flex: @columns;
  -ms-flex: @columns;
  flex: @columns;
}


// Direction
.flex-direction(@direction: row) {
  -webkit-box-orient: @direction;
  -webkit-box-direction: @direction;
  -webkit-flex-direction: @direction;
  -moz-flex-direction: @direction;
  -ms-flex-direction: @direction;
  flex-direction: @direction;
}


// Wrap
.flex-wrap(@wrap: nowrap) {
  -webkit-flex-wrap: @wrap;
  -moz-flex-wrap: @wrap;
  -ms-flex-wrap: @wrap;
  flex-wrap: @wrap;
}


// Source Order
.flex-order(@order: 0) {
  -webkit-box-ordinal-group: @order;
  -webkit-order: @order;
  -moz-order: @order;
  -ms-order: @order;
  -ms-flex-order: @order;
  order: @order;
}

// Grow
.flex-grow(@grow: 0) {
  -webkit-flex-grow: @grow;
  -moz-flex-grow: @grow;
  -ms-flex-grow: @grow;
  -ms-flex-positive: @grow;
  flex-grow: @grow;
}


// Justify Content
.justify-content(@justify: flex-start) {
  webkit-box-pack: @justify;
  -webkit-justify-content: @justify;
  -moz-justify-content: @justify;
  -ms-justify-content: @justify;
  -ms-flex-pack: @justify;
  justify-content: @justify;
}


// Align Content
.align-content(@align: stretch) {
  -webkit-align-content: @align;
  -moz-align-content: @align;
  -ms-align-content: @align;
  -ms-flex-line-pack: @align;
  align-content: @align;
}


// Align Self
.align-self(@align: center) {
  -webkit-align-self: @align;
  -moz-align-self: @align;
  -ms-align-self: @align;
  -ms-flex-item-align: @align;
  align-self: @align;
}

// Align Items
.align-items(@align: stretch) {
  -webkit-align-items: @align;
  -moz-align-items: @align;
  -ms-align-items: @align;
  -ms-flex-align: @align;
  align-items: @align;
}

.flex-align(@v-align: flex-start, @h-align: flex-start) {
  .flex-box;
  .align-items(@v-align);
  .justify-content(@h-align);
}


/* These are the conditional mixins for the different syntax for IE10 Flexbox*/
.ms-flex-justify(@msJustify) when (@msJustify =space-between) {
  -ms-flex-pack: justify;
}

.ms-flex-justify(@msJustify) when (@msJustify =space-around) {
  -ms-flex-pack: distribute;
}

.ms-flex-justify(@msJustify) when (@msJustify =flex-end) {
  -ms-flex-pack: end;
}

.ms-flex-justify(@msJustify) when (@msJustify =flex-start) {
  -ms-flex-pack: start;
}

.ms-flex-justify(@msJustify) when (@msJustify =center) {
  -ms-flex-pack: center;
}

.ms-flex-align-self(@msSelfAlign) when (@msSelfAlign =flex-end) {
  -ms-flex-item-align: end;
}

.ms-flex-align-self(@msSelfAlign) when (@msSelfAlign =flex-start) {
  -ms-flex-item-align: start;
}

.ms-flex-align-self(@msSelfAlign) when (@msSelfAlign =auto),
(@msSelfAlign =center),
(@msSelfAlign =baseline),
(@msSelfAlign =stretch) {
  -ms-flex-item-align: @msSelfAlign;
}