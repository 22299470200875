.checkbox-round {
  .ant-checkbox {
    color: #fff;
  }

  .ant-checkbox-inner {
    background: transparent;
    border: 2px solid @gray;
    border-radius: 25px;
    width: 22px;
    height: 22px;
  }

  .ant-checkbox+span {
    position: relative;
    top: -3px;
  }

  .ant-checkbox-checked::after {
    border: 2px solid @green-base;
    border-radius: 25px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @green-base;
    border-color: @green-base;
  }

  &:hover .ant-checkbox-inner,
  //.ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: @green-base;
  }

  .ant-checkbox-inner::after {
    top: 45%;
    left: 20%;
  }
}