.spacing() {
  0: 0;
  01: 0.1rem;
  03: 0.3rem;
  05: 0.5rem;
  075: 0.75rem;
  1: 1rem;
  2: 1.5rem;
  3: 2rem;
  4: 2.5rem;
  5: 3rem;
  6: 5rem;
}

.props() {
  m: margin;
  p: padding;
}

.sides() {
  t: top;
  b: bottom;
  l: left;
  r: right;
}

each(.props(), .(@propVal, @propKey) {
    each(.spacing(), .(@selectVal, @selectKey) {
        .@{propKey}-@{selectKey} {
          @{propVal}: @selectVal !important;
        }

        each(.sides(), .(@sideVal, @sidekey) {
            .@{propKey}@{sidekey}-@{selectKey} {
              @{propVal}-@{sideVal}: @selectVal !important;
            }
          });
      });
  });

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt--1 {
  margin-top: -1rem;
}

.ml--1 {
  margin-left: -1rem;
}

.-mr-4px {
  margin-right: -4px;
}

each(.spacing(), .(@selecVal, @selecKey) {
    .gap-@{selecKey} {
      gap: @selecVal !important;
    }
  });