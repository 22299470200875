.gradient(@type; @colors; @dir: 0deg; @prefixes: webkit, moz, ms, o; @index: length(@prefixes)) when (@index > 0) {
  .gradient(@type; @colors; @dir; @prefixes; (@index - 1));

  @prefix: extract(@prefixes, @index);
  @dir-old: 90 - (@dir);

  background:~"-@{prefix}-@{type}-gradient(@{dir-old}, @{colors})";

  & when (@index =length(@prefixes)) {
    background:~"@{type}-gradient(@{dir}, @{colors})";
  }
}

.disabled-bg(@bg: transparent, @opacity: 0.035) {
  background: linear-gradient(45deg,
      rgba(#000, @opacity) 5%,
      @bg 5%,
      @bg 45%,
      rgba(#000, @opacity) 45%,
      rgba(#000, @opacity) 55%,
      @bg 55%,
      @bg 95%,
      rgba(#000, @opacity) 95%);
  background-size: 22px 22px;
}

.inner-round(@bg: #F2F3F8, @size: 20px) {
  background: -moz-radial-gradient(0 100%,
      circle,
      rgba(204, 0, 0, 0) @size,
      @bg (@size + 1)),
    -moz-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) @size, @bg (@size + 1)),
    -moz-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) @size, @bg (@size + 1)),
    -moz-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) @size, @bg (@size + 1));
  background: -webkit-radial-gradient(0 100%,
      circle,
      rgba(204, 0, 0, 0) @size,
      @bg (@size + 1)),
    -webkit-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) @size, @bg (@size + 1)),
    -webkit-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) @size, @bg (@size + 1)),
    -webkit-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) @size, @bg (@size + 1));
  background: radial-gradient(circle at 0 100%,
      rgba(204, 0, 0, 0) @size,
      @bg (@size + 1)),
    radial-gradient(circle at 100% 100%,
      rgba(204, 0, 0, 0) @size,
      @bg (@size + 1)),
    radial-gradient(circle at 100% 0, rgba(204, 0, 0, 0) @size, @bg (@size + 1)),
    radial-gradient(circle at 0 0, rgba(204, 0, 0, 0) @size, @bg (@size + 1));

  background-position: bottom left, bottom right, top right, top center;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}