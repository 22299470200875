.add-divider-btn {
  position: relative;
  display: block;
  border: none;
  width: 100%;
  height: 24px;
  margin: 6px 0;
  background: unset;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    top: calc(50% - 1px);
    background-color: rgba(0, 0, 0, 0.06);
    transition: background-color @transition, height @transition;
  }

  &::before {
    content: "+";
    opacity: 0;
    font-size: 16px;
    color: @primary-color;
    position: absolute;
    height: 24px;
    display: block;
    border-radius: 25px;
    transition: opacity @transition;
    z-index: 2;
    box-shadow: 0px 1px 5px rgba(@primary-color, 0.6);
    width: 24px;
    left: calc(50% - 12px);
    top: 0;
    background: #fff;
  }

  &:hover,
  &:focus {
    outline: none;

    &:not(:disabled) {
      cursor: pointer;

      &::after {
        height: 2px;
        background: @primary-color;
      }

      &::before {
        opacity: 1;
      }
    }
  }
}