.blur-banner {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  z-index: 3;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11px);
    border-radius: 6px;
  }
}