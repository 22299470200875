.login h1 {
  margin-bottom: 24px;

  &::after {
    content: "";
    display: block;
    margin: 13px 0;
    height: 2px;
    width: 40px;
    line-height: 2px;
    background-color: @primary-color;
  }
}

.login {
  .Mq(@screen-lg; {
      display: block;

      .login-mobile-logo > svg {
        display: block;
        margin: 2rem 1rem;
      }
    });
}

.login-mobile-logo {
  .flex-grow(1);
  .flex-box();
  .align-items(flex-end);

  svg> {
    display: none;
    min-width: 288px;
    min-height: 100px;
  }
}

.login-form-wp {
  background-color: #fff;
  min-height: 100vh;
  padding: 24px;
  .flex-align(center, center);
  .flex-direction(column);

  .ant-form {
    min-width: 360px;

    .Mq(@screen-xs; {
        min-width: auto;
      });
  }

  &.signup-form-wp {

    text-align: left;
    .flex-align(start, center);

    .signup-form-wrapper {
      .ant-form {
        width: 100%;
      }

      .Mq(@screen-xs, {
        padding: 0px;
      });
    padding: 30px;
  }
}
}

.login-footer {
  .flex-grow(1);
  .flex-box();
  .align-items(flex-end);
  width: 100%;
  padding-top: 24px;

  .ant-row {
    width: 100%;
  }
}

.login-bg {
  background: linear-gradient(45deg, #162533 60.33%, #176280 133.91%);
  position: relative;

  .flex-align(center, center);

  .Mq(@screen-lg; {
      display: none;
    });
}

.login-bg::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/dmf-pattern-1.png) no-repeat top right;
  background-size: 953px auto;
  background-size: auto;
}

.signup-bg {
  position: relative;
  background: linear-gradient(130deg, #093254 9.89%, rgba(9, 50, 84, 0.06) 103.84%);
  mix-blend-mode: multiply;
}

.signup-bg::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(140deg, #162533 60.33%, #176280 133.91%);
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  pointer-events: none;
  z-index: 0;
  mix-blend-mode: overlay;
}

.signup-bg::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/dmf-pattern-2.png) no-repeat bottom right;
  background-size: 953px auto;
  background-size: auto;
  z-index: 1;
}

.signup-checkmark {
  background-color: #253B4E;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: @primary-color;
  border-radius: 999px;
}

.signup-info-card {
  padding: 48px;
  border-radius: 16px;
  .bg-muted;
  height: 100%;

  .Mq(@screen-sm, {
    padding: 24px;
  });
}

.signup-radio-group {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  .Mq(@screen-md, {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  });

.Mq(@screen-xs, {
  grid-template-columns: repeat(1, minmax(0, 1fr));
});

.ant-radio-wrapper {
  box-shadow: 0px 5.424px 46.105px 0px rgba(0, 0, 0, 0.06), inset 0px 0px 0px 1px #EFF3F5;
  transition: box-shadow @transition, background-color @transition;
  padding: 32px;
  margin: 0 !important;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: stretch;
  background-color: white;

  &>span {
    padding: 0;
  }

  &>span:nth-of-type(2) {
    display: flex;
    flex-direction: column;
  }

  .ant-radio {
    .sr-only
  }

  .title {
    font-size: 20px;
    font-weight: 400;
    color: @primary-color;
  }

  .description {
    margin-bottom: 16px;
  }

  .price {
    color: @dark-blue;
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    margin-top: auto,
  }
}

.ant-radio-wrapper:not(.ant-radio-wrapper-disabled):is(:focus, :focus-within, :focus-visible) {
  box-shadow: 0px 5.424px 46.105px 0px rgba(0, 0, 0, 0.06), inset 0px 0px 0px 1px lighten(@primary-color, 30);
  background-color: lighten(@primary-color, 47);
}

.ant-radio-wrapper:not(.ant-radio-wrapper-disabled):hover {
  box-shadow: 0px 5.424px 46.105px 0px rgba(0, 0, 0, 0.06), inset 0px 0px 0px 1px lighten(@primary-color, 30);
  background-color: lighten(@primary-color, 47);
}

.ant-radio-wrapper-checked {
  background-color: lighten(@primary-color, 49);
  box-shadow: 0px 5.424px 46.105px 0px rgba(0, 0, 0, 0.06), inset 0px 0px 0px 3px @primary-color !important;
}

.ant-radio-wrapper-disabled {
  border: @border-light !important;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.035) 5%, #eff2f5 5%, #eff2f5 45%, rgba(0, 0, 0, 0.035) 45%, rgba(0, 0, 0, 0.035) 55%, #eff2f5 55%, #eff2f5 95%, rgba(0, 0, 0, 0.035) 95%);
  background-size: 22px 22px;
}
}