.text-left {
  text-align: left !important;
}

.position-relative {
  position: relative;
}

.position-static {
  position: static;
}

.position-absolute {
  position: absolute;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.absolute-right {
  position: absolute;
  right: 0;
}

.md-min-top-2px {
  top: 2px;
}

@media (max-width: 990px) {
  .md-max-top-33px {
    top: 33px;
  }
}

.top-0 {
  top: 0;
}

.top-minus-05 {
  top: -0.5rem;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.left-124 {
  left: 124px;
}

.spacing-pre-line {
  white-space: pre-line;
}

.v-align-top {
  vertical-align: top;
}

.v-align-middle {
  vertical-align: middle;
}

.v-align-bottom {
  vertical-align: bottom;
}

.border-radius {
  border-radius: @card-radius;
}

.no-border {
  border-color: transparent !important;
}

.d-none {
  display: none;
}

.mobile-hide {
  .Mq(@screen-sm, {
    display: none;
  })
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.text-italic {
  font-style: italic;
}

.text-white,
a.text-white {
  color: #fff;
}

.text-white-important {
  color: #fff !important;
}

.text-dark,
a.text-dark {
  color: @dark-blue;
}

.text-dark2,
a.text-dark2 {
  color: @dark-blue2;
}

.text-gray,
a.text-gray {
  color: @gray;
}

.text-body-background {
  color: @body-background;
}

.text-success,
a.text-success {
  color: @green-base;
}

.text-danger,
a.text-danger {
  color: @red-base;
}

.text-dark-blue,
a.text-dark-blue {
  color: @dark-blue;
}

.input-text-dark input,
input.input-text-dark {
  color: @dark-blue;
}

.text-lg-right {
  .Mq-min(@screen-lg, {
    text-align: right !important;
  });
}

.text-xl-right {
  .Mq-min(@screen-xl, {
    text-align: right !important;
  });
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-bold {
  font-weight: bold;
}

.input-text-bold input,
input.input-text-bold {
  font-weight: bold;
}

.input-text-medium input,
input.input-text-medium {
  font-weight: 500;
}

.font-courier {
  font-family: "Courier New", monospace;
}

.text-medium {
  font-weight: 500;
}

.text-semibold {
  font-weight: 600;
}

.text-normal {
  font-weight: normal;
}

.text-lighter {
  font-weight: 300;
}

.text-large {
  font-size: 15px;
}

.text-16 {
  font-size: 16px;
}

.text-larger {
  font-size: 17px;
}

.text-huge {
  font-size: 19px;
}

.text-50 {
  font-size: 34px;

  .Mq-min(@screen-lg; {
      font-size: 44px
    });

  .Mq-min(@screen-xl; {
      font-size: 50px
    });
}

.text-40 {
  font-size: 28px;

  .Mq-min(@screen-lg; {
      font-size: 36px
    });

  .Mq-min(@screen-xl; {
      font-size: 40px
    });
}

.font-36 {
  font-size: 36px;

  .Mq(@screen-lg; {
      font-size: 28px
    });
}

.text-30 {
  font-size: 24px;

  .Mq-min(@screen-lg; {
      font-size: 30px
    });
}

.text-24 {
  font-size: 20px;

  .Mq-min(@screen-lg; {
      font-size: 24px
    });
}

.text-small {
  font-size: 13px;
}

.text-smaller {
  font-size: 12px;
}

.text-smallest {
  font-size: 11px;
}

.text-tiny {
  font-size: 10px;
}

.text-warning {
  color: @orange-base;
}

.text-success {
  color: @green-base;
}

.text-success-important {
  color: @green-base !important;
}

.text-primary {
  color: @primary-color;
}

.text-warning-important {
  color: @orange-base !important;
}

.text-primary-important {
  color: @primary-color !important;
}

.text-danger-important {
  color: @red-base !important;
}

.no-bg,
.background-none {
  background: none !important;
}

.w-100 {
  width: 100%;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

.d-table {
  display: table;
}

.d-table-cell {
  display: table-cell;
}

.flex-none {
  flex: none;
}

.text-secondary {
  color: @secondary-text;
}

.text-secondary-important {
  color: @secondary-text !important;
}

.text-gray {
  color: @gray;
}

.muted {
  color: @muted-text;
}

a.muted:hover {
  color: @accent-color;
}

.hover-color-red:hover {
  color: @red-base !important;
}

.hover-color-dark:hover {
  color: @dark-blue !important;
}

.hover-color-primary:hover {
  color: @primary-color !important;
}

.bg-warning {
  background-color: lighten(@orange-base, 38%);
}

.bg-warning-darker {
  background-color: lighten(@orange-base, 20%) !important;
}

.bg-danger-ligth {
  background-color: rgba(@red-base, 0.2);
}

.bg-dark-blue {
  background-color: @dark-blue;
}

.bg-dark-blue\! {
  background-color: @dark-blue !important;
}

.bg-dark-blue2 {
  background-color: @dark-blue2;
}

.bg-dark-blue3 {
  background-color: @dark-blue3;
}

.bg-white {
  background-color: #fff;
}

.bg-white-20 {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-light,
.bg-light .ant-table-cell-fix-left.ant-table-cell {
  background-color: @background-color-light;
}

.bg-light-primary {
  background-color: rgba(@primary-color, 0.2);
}

.bg-muted {
  background-color: @light-bg;
}

.hover-bg-muted {

  &:focus,
  &:focus-visible,
  &:hover {
    background-color: darken(@light-bg, 2) !important;
  }
}

.fit-content-height {
  height: fit-content;
}

.height-100 {
  height: 100%;
}

.height-64 {
  height: 64px;
}

.h-55px {
  height: 55px
}

.h-80px {
  height: 80px
}

.mx-height-32 {
  max-height: 32px;
}

.min-height-100 {
  min-height: 100px;
}

.min-height-40 {
  min-height: 40px;
}

.min-height-200 {
  min-height: 200px;
}

.min-height-300 {
  min-height: 300px;
}

.height-auto {
  height: auto !important;
}

.min-width-125 {
  min-width: 125px;
}

.min-width-180 {
  min-width: 180px;
}

.min-width-240 {
  min-width: 240px;
}

.mx-width-100 {
  max-width: 100% !important;
}

.mx-width-125 {
  max-width: 125px !important;
}

.mx-width-180 {
  max-width: 180px !important;
}

.max-width-240 {
  max-width: 240px !important;
}

.mx-width-340 {
  max-width: 340px !important;
}

.mx-width-440 {
  max-width: 440px !important;
}

.max-width-720 {
  max-width: 720px !important;
}

.max-width-full-with-offset {
  max-width: calc(100% - 50px) !important;
}

.width-1 {
  width: 1rem;
}

.width-38 {
  width: 38px;
}

.width-64 {
  width: 64px;
}

.w-55px {
  width: 55px
}

.w-80px {
  width: 80px
}

.width-125 {
  width: 125px;
}

.width-180 {
  width: 180px;
}

.width-240 {
  width: 240px;
}

.max-width-448 {
  width: 448px;
}

.m--4 {
  margin: -2.5rem !important;
}

.mb-0-important {
  margin-bottom: 0 !important;
}

.mb-xl-0 {
  .Mq(@screen-xl; {
      margin-bottom: 0px !important;
    });
}

.mt-xl-2 {
  .Mq(@screen-xl; {
      margin-top: 2rem !important;
    });
}

.transition {
  transition: all @transition;
}

.transition-05 {
  transition: all 0.5s ease-in-out;
}

.overflow-x-auto {
  overflow-x: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}


.d-flex {
  .flex-box;
}

.flex-column {
  .flex-direction(column);
}

.flex-grow {
  .flex-grow(1);
}

.flex-0\! {
  flex: 0 0 auto !important;
}

.flex-wrap-lg {
  .Mq-min(@screen-lg; {
      .flex-wrap();
    });
}

.flex-wrap {
  .flex-wrap(wrap);
}

.flex-nowrap {
  .flex-wrap(nowrap);
}


.align-stretch {
  .align-items(stretch);
}

.text-nowrap {
  white-space: nowrap;
}

.no-shadow {
  box-shadow: none !important;
}

.min-width-0 {
  min-width: 0;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.bg-none {
  background: none !important;
}

.bg-primary {
  background-color: @primary-color;
}

.border-primary {
  border-color: @primary-color !important;
}

.border-light {
  border: 1px solid @body-background;
}

.border-light-2 {
  border: 2px solid @body-background;
}

.border-success {
  border-color: rgba(@green-base, 0.4);
}

.border-rounded {
  border-radius: @btn-border-radius-base !important;
}

.rounded-16 {
  border-radius: 16px !important;
}

.border-rounded-full {
  border-radius: 9999px !important;
}

.border-round {
  border-radius: 50% !important;
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.rotate-180 {
  transform: rotate(180deg);
}

.justify-content-end {
  .justify-content(flex-end);
}

.justify-content-center {
  .justify-content(center);
}

.justify-content-between {
  .justify-content(space-between);
}

.align-items-center {
  .align-items(center);
}

.align-items-end {
  .align-items(flex-end);
}


.text-strike {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-green {
  color: @green-base;

  &:focus {
    color: @green-base;
  }
}

.text-upper {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lower {
  text-transform: lowercase;
}

.text-blue {
  color: @blue-base;

  &:focus {
    color: @blue-base;
  }
}

.text-light-blue {
  color: @accent-color-light;

  &:focus {
    color: @accent-color-light;
  }
}

.hover-text-green-dark:hover {
  color: darken(@green-base, 15);
}

.hover-border-primary:hover {
  border-color: @primary-color !important;
}

.text-inherit {
  color: inherit;
}

.pointer-event-none * {
  pointer-events: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 50%;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.object-cover {
  object-fit: cover;
}

.object-position-50-13 {
  object-position: 50% 13%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.u5kr7b4r6vg {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.checkbox-btn {
  padding: 8px 16px;
  background-color: rgba(@primary-color, 0.1);
  border-radius: @btn-border-radius-base;
}