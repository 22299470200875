.notifications-drawer {
  .ant-drawer-header {
    border-bottom: 0;
  }

  .ant-drawer-body {
    padding: 0;
    background-color: @light-bg;
  }

  .ant-tabs-nav {
    background-color: #fff;
    margin-bottom: 0;
  }

  .ant-tabs-nav-wrap {
    padding: 0;
  }

  .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab-btn {
    padding: 0 24px;
  }
}

.notification-item {
  padding: 16px 24px;
  background-color: #fff;
  border-bottom: darken(@light-bg, 3) 1px solid;
  transition: background-color @transition;

  &.is-priority:not(.is-read) {
    background-color: lighten(@red-base, 41);

    .is-priority-icon {
      color: @red-base;
    }

    .notification-item-title {
      color: @red-base;
    }
  }

  .is-priority-icon {
    color: @muted-text;
  }

  .notification-item-title {
    font-weight: bold;
    width: 100%;
  }

  .notification-item-message {
    margin: 0;
  }

  a.ant-typography,
  .notification-item-date {
    font-size: 13px;
  }

  .is-read-btn {
    color: @muted-text;
    transition: opacity @transition;
  }
}

.Mq-min(@screen-md; {
    .is-read-btn {
      opacity: 0;
    }

    .notification-item:hover .is-read-btn {
      opacity: 1;
    }
  });

.is-read {
  background-color: transparent;

  .notification-item-message,
  .notification-item-title,
  .notification-item-link {
    opacity: 0.75;
    transition: opacity @transition;
  }

  &:hover {
    background-color: rgba(#fff, 0.6);

    .notification-item-message,
    .notification-item-title,
    .notification-item-link {
      opacity: 1;
    }
  }
}

.mark-all-read {
  font-size: 14px;
  margin-right: 10px;
  color: @muted-text;
}