// Header

header.ant-layout-header {
  .Mq(@screen-md; {
      height: 64px;
      line-height: 64px;
    });
}

.danger-border-bottom {
  border-bottom: 3px solid rgba(@red-base, 0.4);
}

.ant-page-header-back-button .chevron-back-outline-icon {
  font-size: 26px;
}

.ant-page-header,
.ant-page-header.fluid-page-title {
  padding-bottom: 0;
}

.header-heading-margin .ant-page-header-heading {
  padding-bottom: 16px;
}

.ant-page-header-heading-title {
  font-weight: 300;
  line-height: 32px;
}

.ant-page-header-footer {
  padding-bottom: 8px;
}

.page-head-btn {
  margin-left: 6px;
  line-height: 32px;
  font-size: 22px;
  padding: 0 14px;
  color: @secondary-text;

  &:last-child {
    padding-right: 0;
  }

  .anticon {
    margin-right: 5px;
    vertical-align: -0.15em !important;
  }

  &:hover {
    color: @dark-blue;
  }
}

.back-btn {
  cursor: pointer;
  font-size: 15px;
  color: @secondary-text;
  transition: color @transition;

  .anticon {
    margin-right: 5px;
    vertical-align: -0.15em;
  }

  &:hover {
    color: @dark-blue;
  }
}

.user-area {
  margin-left: 10px;

  .ant-avatar {
    background: @primary-color;
  }
}

.user-name {
  padding-left: 5px;
  width: 80px;
}

// Footer

.ant-layout-footer {
  color: @secondary-text;

  .ant-col {
    .Mq(@screen-md; {
        text-align: center
      });
  }
}

// main content wrapper
main.content {
  width: 100%;
  height: 100%;

  &:not(.tax) {
    max-width: 1800px;
  }

  padding: 24px;
  margin: 0 auto;

  .Mq(@screen-md; {
      padding: 12px;
    });

  &.content-fluid {
    max-width: unset !important;
  }
}

.content-bg {
  box-shadow: @shadow;
  background-color: #fff;
  border-radius: @border-radius-base;
}

.fluid-page-title {
  background-color: #fff;
  padding: 16px 24px;
  margin: -22px -24px 24px -24px;

  .Mq(@screen-md; {
      margin: -10px -12px 24px -12px;
    });

  .ant-page-header-content {
    padding-top: 0;
  }
}

// Side menu

.side-menu-mobile .ant-drawer-body {
  padding: 0;
  background-color: @layout-sider-background;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}

.ant-menu-dark .anticon {
  color: @text-color-secondary-dark;
}

.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-item-active .anticon {
  color: @accent-color;
}

.ant-menu hr {
  border: 0;
  border-top: 1px solid @dark-light-bg;
}

.logo-wp {
  margin: 20px;
  width: 200px;
  height: 76px;
  transition: width 250ms ease;

  svg {
    transition: width 250ms ease, height 250ms ease;
    width: 160px;
    height: 70px;
  }
}

.ant-layout-sider-collapsed {
  .logo-wp {
    width: 40px;
    overflow: hidden;

    svg {
      width: 118px;
      height: 51px;
    }
  }

  .collapsible-subscription-info {
    padding: 0px !important;
    align-items: center;
    width: 80px;

    .hide-on-collapse {
      display: none;
    }
  }
}

.ant-layout-sider-has-trigger {
  padding-bottom: 58px;
}

.ant-layout-sider-trigger {
  height: 58px;
  float: right;
  position: fixed;
  .gradient(linear; @layout-sider-background, @layout-sider-background, transparent);

  &::before,
  &::after,
  span {
    top: 22px;
    position: absolute;
    right: 22px;
    display: block;
    width: 26px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.35);
    border-radius: @border-radius-base;
    transition: background-color @transition, width @transition, right @transition;
  }

  &::before {
    content: "";
    top: 12px;
    right: 32px;
    width: 16px;
  }

  &::after {
    content: "";
    top: 32px;
    right: 28px;
    width: 20px;
  }

  &:hover {

    &::before,
    &::after,
    span {
      width: 16px;
      right: 32px;
      background-color: #fff;
    }
  }
}

.sider-menu {
  position: fixed;
  height: 100%;
  padding-bottom: 80px;
  overflow-y: auto;
  z-index: 1;
}

.ant-layout-sider-collapsed {
  .ant-layout-sider-trigger {

    &::before,
    &::after,
    span {
      width: 16px;
      right: 32px;
      background-color: rgba(255, 255, 255, 0.65);
    }

    &:hover {
      &::before {
        width: 16px;
        right: 32px;
        background-color: #fff;
      }

      &::after {
        width: 20px;
        right: 28px;
        background-color: #fff;
      }

      span {
        width: 26px;
        right: 22px;
        background-color: #fff;
      }
    }
  }
}