.recommended-package-badge {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  .ant-badge-count {
    background: @accent-color-light;
    color: #0299FB;
    padding: 5px 16px;
    height: auto;
    box-shadow: none;
    font-weight: bold;
    border-radius: 14px;
  }
}

.subscription-items-row {
  flex-wrap: wrap;

  >.ant-col {
    flex: 100%;

    .Mq-min(@screen-sm, {
      flex: 50%;
    });

  .Mq-min(@screen-xl, {
    flex: calc(100% / 6);
  });
}

.package-element-visible-xxl {
  .Mq(@screen-xxl, {
    display: none;
  });
}
}